import { useNavigate, useRouteLoaderData } from "@remix-run/react";
import { HiOutlineBars4 } from "react-icons/hi2/index.js";
import { useSupabaseClient } from "~/contexts/SupabaseClientContext/SupabaseClientContext";
import type { loader } from "~/routes/_application";
import { useTrpc } from "~/utils/TrpcClientProvider";
import { HiUser } from "react-icons/hi/index.js";
import { twMerge } from "tailwind-merge";

export const Menu = ({ className }: { className?: string }) => {
  const { supabaseClient } = useSupabaseClient();
  const rootData = useRouteLoaderData<typeof loader>("routes/_application");
  const navigate = useNavigate();
  const {
    resetCurrentDemoUser: { useMutation },
  } = useTrpc();
  const { mutate: resetCurrentDemoUser } = useMutation({
    onSuccess: () => {
      supabaseClient.auth.signOut();
    },
  });

  if (!rootData) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "hs-dropdown relative inline-flex [--trigger:hover]",
        className
      )}
    >
      <button
        id="hs-dropdown-hover-event"
        type="button"
        className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-background text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
      >
        <HiOutlineBars4 />
      </button>

      <div
        className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[15rem] bg-background shadow-md rounded-lg p-2 mt-2 after:h-4 after:absolute after:-bottom-4 after:left-0 after:w-full before:h-4 before:absolute before:-top-4 before:left-0 before:w-full"
        aria-labelledby="hs-dropdown-hover-event"
      >
        <span className="text-gray-800 text-sm flex gap-2 items-center mb-2">
          <span>
            <HiUser />
          </span>
          <span>{rootData.user?.email}</span>
        </span>
        <a
          className="cursor-pointer flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
          target="_blank"
          href="https://www.switchgrid.tech/cgu"
          rel="noreferrer"
        >
          Conditions générales d'utilisation
        </a>
        <a
          className="cursor-pointer flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
          onClick={() =>
            supabaseClient.auth.signOut().then(() => {
              navigate("/login");
            })
          }
        >
          Me déconnecter
        </a>
        {rootData.user?.isDemoUser && (
          <a
            className="cursor-pointer flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
            onClick={() => resetCurrentDemoUser()}
          >
            ⚠️ DEMO: RAZ
          </a>
        )}
      </div>
    </div>
  );
};
